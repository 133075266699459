<template>
    <div class="root-page">
        <TopBanner />
        <div class="common-layout">
            <el-container>
                <el-header>
                    <!-- 导航 -->
                    <el-tabs
                        type="border-card"
                        v-model="activeName"
                        @tab-click="handleClick">
                        <!-- 用户管理 -->
                        <el-tab-pane label="用户管理" name="first">
                            <el-table :data="userList">
                                <el-table-column
                                    prop="uid"
                                    label="id"
                                    width="180" />
                                <el-table-column
                                    prop="uname"
                                    label="用户名"
                                    width="180" />
                                <el-table-column prop="account" label="账号" />
                                <el-table-column prop="lib_name" label="数据库" />
                                <!-- 用户状态 -->
                                <el-table-column prop="type" label="状态">
                                    <template #default="scope">
                                        <el-tag
                                            :type="
                                                scope.row.status === '正常'
                                                    ? 'success'
                                                    : 'danger'
                                            ">
                                            {{ scope.row.status }}</el-tag
                                        >
                                    </template>
                                </el-table-column>
                                <!-- 按钮 -->
                                <el-table-column label="操作">
                                    <template #default="scope">
                                        <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            v-show="
                                                !(scope.row.status === '正常')
                                                    ? false
                                                    : true
                                            "
                                            @click="switching(scope)">
                                            禁用
                                        </el-button>
                                        <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            v-show="
                                                scope.row.status === '正常'
                                                    ? false
                                                    : true
                                            "
                                            @click="switching(scope)">
                                            启用
                                        </el-button>
                                        <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            @click="changePassword(scope.row)"
                                            >修改</el-button
                                        >
                                        <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            @click.prevent="deleteRow(scope)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button
                                class="mt-4"
                                style="width: 100%"
                                @click="openForm">
                                添加用户
                            </el-button>
                        </el-tab-pane>
                        <!-- 文件上传 -->
                        <el-tab-pane label="Excel上传" name="second">
                            <Inport
                                :parentMsg="libList"
                                :personnelShow="true" />
                        </el-tab-pane>
                        <el-tab-pane label="PDF上传" name="third">
                            <Inport
                                :parentMsg="userList"
                                :personnelShow="false" />
                        </el-tab-pane>
                        <el-tab-pane label="数据库管理" name="four">
                            <el-table :data="libList">
                                <el-table-column prop="uid" label="id" />
                                <el-table-column prop="uname" label="数据库" />

                                <!-- 按钮 -->
                                <el-table-column label="操作">
                                    <template #default="scope">
                                        <el-button
                                            link
                                            type="primary"
                                            size="small"
                                            @click.prevent="
                                                deleteRowLib(scope)
                                            ">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button
                                class="mt-4"
                                style="width: 100%"
                                @click="this.dialogFormVisibleLib = true">
                                添加数据库
                            </el-button>
                        </el-tab-pane>
                    </el-tabs>
                </el-header>
            </el-container>
        </div>
        <!-- 弹窗  新增库 -->
        <el-dialog v-model="dialogFormVisibleLib" :title="'添加库'" width="500">
            <el-form :model="form">
                <el-form-item label="库名" prop="libname" label-width="140px">
                    <el-input
                        clearable
                        v-model="form.libname"
                        autocomplete="off" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogFormVisibleLib = false"
                        >取消</el-button
                    >
                    <el-button type="primary" @click="onAddItemLib">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <!-- 弹窗  填写用户信息  修改用户密码 -->
        <el-dialog
            v-model="dialogFormVisible"
            :title="this.userDisabled == false ? '添加用户' : '修改密码'"
            width="500">
            <el-form :model="form" :rules="userFormRules">
                <el-form-item
                    v-show="userDisabled"
                    label="用户id"
                    label-width="140px">
                    <el-input
                        :disabled="userDisabled"
                        v-model="form.uid"
                        autocomplete="off" />
                </el-form-item>
                <el-form-item
                    label="登录账号"
                    prop="account"
                    label-width="140px">
                    <el-input
                        clearable
                        :disabled="userDisabled"
                        v-model="form.account"
                        autocomplete="off" />
                </el-form-item>
                <el-form-item
                    label="用户名"
                    prop="username"
                    label-width="140px">
                    <el-input
                        clearable
                        :disabled="userDisabled"
                        v-model="form.username"
                        autocomplete="off" />
                </el-form-item>
                <el-form-item label="密码" prop="password" label-width="140px">
                    <el-input
                        type="password"
                        show-password
                        clearable
                        v-model="form.password"
                        autocomplete="off" />
                </el-form-item>
                <!-- <el-form-item label="数据库" prop="libs" label-width="140px">
              <el-input type="password" show-password clearable v-model="form.password" autocomplete="off" />
            </el-form-item> -->
                <el-form-item label="数据库" prop="libs" label-width="140px">
                    <el-select
                        v-model="form.lib_id"
                        placeholder="请选择所属数据库">
                        <el-option
                            v-for="item in libList"
                            :key="item.uid"
                            :label="item.uname"
                            :value="item.uid" />
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                        >取消</el-button
                    >
                    <el-button type="primary" @click="onAddItem">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import TopBanner from './TopBanner.vue'
    import { ElMessage } from 'element-plus'
    import { ref, onMounted } from 'vue'
    import Inport from './InportPage.vue'
    import axios from 'axios'

    export default {
        components: {
            TopBanner,
            Inport,
        },
        data() {
            return {
                form: {
                    uid: '',
                    account: '',
                    username: '',
                    password: '',
                },
                userFormRules: {
                    // 验证账号是否合法
                    account: [
                        {
                            required: true,
                            message: '请输入登录名称',
                            trigger: 'blur',
                        },
                        {
                            min: 1,
                            max: 10,
                            message: '长度在 1 到 10 个字符',
                            trigger: 'blur',
                        },
                    ],
                    // 验证用户名是否合法
                    username: [
                        {
                            required: true,
                            message: '请输入用户名称',
                            trigger: 'blur',
                        },
                        {
                            min: 1,
                            max: 10,
                            message: '长度在 1 到 10 个字符',
                            trigger: 'blur',
                        },
                    ],
                    // 验证密码是否合法
                    password: [
                        {
                            required: true,
                            message: '请输入登录密码',
                            trigger: 'blur',
                        },
                        {
                            min: 1,
                            max: 15,
                            message: '长度在 1到 15 个字符',
                            trigger: 'blur',
                        },
                    ],
                },
                dialogFormVisible: false,
                dialogFormVisibleLib: false,
                userDisabled: false,
                activeName:
                    window.localStorage.getItem('activeTab ') || 'first',
            }
        },
        methods: {
            openForm() {
                this.dialogFormVisible = true
                this.userDisabled = false
                this.form.uid = ''
                this.form.username = ''
                this.form.password = ''
                this.form.account = ''
            },
            handleClick(tab) {
                this.activeName = tab.props.name
                window.localStorage.setItem('activeTab ', this.activeName)
            },
            // 用户禁用启用状态
            switching(e) {
                let a = e.row.status == '正常' ? 2 : 1
                axios
                    .post('/api/user_update', {
                        uid: e.row.uid,
                        type: a,
                    })
                    .then((res) => {
                        if (res.data.meta.status == 200) {
                            location.reload()
                            ElMessage({
                                message: '更新成功',
                                type: 'success',
                                plain: true,
                            })
                        } else {
                            ElMessage({
                                message: '更新失败',
                                type: 'error',
                                plain: true,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            // 删除用户
            deleteRow(e) {
                axios
                    .post('/api/user_update', {
                        uid: e.row.uid,
                        type: 3,
                    })
                    .then((res) => {
                        if (res.data.meta.status == 200) {
                            location.reload()
                            ElMessage({
                                message: '删除成功',
                                type: 'success',
                                plain: true,
                            })
                        } else {
                            ElMessage({
                                message: '删除失败',
                                type: 'error',
                                plain: true,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            // 删除库
            deleteRowLib(e) {
                axios
                    .post('/api/lib_update', {
                        id: e.row.uid,
                    })
                    .then((res) => {
                        if (res.data.meta.status == 200) {
                            location.reload()
                            ElMessage({
                                message: '删除成功',
                                type: 'success',
                                plain: true,
                            })
                        } else {
                            ElMessage({
                                message: '删除失败',
                                type: 'error',
                                plain: true,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            // 添加库
            async onAddItemLib() {
                this.dialogFormVisibleLib = false
                axios
                    .post('/api/add_lib', {
                        name: this.form.libname,
                    })
                    .then((res) => {
                        if (res.data.meta.status == 200) {
                            location.reload()
                            ElMessage({
                                message: '添加成功',
                                type: 'success',
                                plain: true,
                            })
                        } else {
                            ElMessage({
                                message: '添加失败',
                                type: 'error',
                                plain: true,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(111, err)
                    })
            },

            // 添加用户  修改用户密码
            async onAddItem() {
                this.dialogFormVisible = false
                if (this.userDisabled == false) {
                    // 判断用户名是否存在
                    let obj = -1
                    if (this.userList != undefined) {
                        obj = this.userList.findIndex((item) => {
                            return item.account == this.form.account
                        })
                    }
                    if (obj == -1) {
                        await axios
                            .post('/api/user_add', {
                                account: this.form.account,
                                username: this.form.username,
                                password: this.form.password,
                                belong_lib: this.form.lib_id,
                            })
                            .then((res) => {
                                if (res.data.meta.status == 200) {
                                    location.reload()
                                    ElMessage({
                                        message: '用户添加成功',
                                        type: 'success',
                                        plain: true,
                                    })
                                } else {
                                    ElMessage({
                                        message: '用户添加失败',
                                        type: 'error',
                                        plain: true,
                                    })
                                }
                            })
                            .catch((err) => {
                                console.log(111, err)
                            })
                    } else {
                        ElMessage({
                            message: '该账号已存在',
                            type: 'error',
                            plain: true,
                        })
                    }
                } else {
                    axios
                        .post('/api/user_update', {
                            uid: this.form.uid,
                            password: this.form.password,
                            lib_id: this.form.lib_id,
                        })
                        .then((res) => {
                            if (res.data.meta.status == 200) {
                                location.reload()
                                ElMessage({
                                    message: '密码修改成功',
                                    type: 'success',
                                    plain: true,
                                })
                            } else {
                                ElMessage({
                                    message: '密码修改失败',
                                    type: 'error',
                                    plain: true,
                                })
                            }
                        })
                        .catch((err) => {
                            console.log(111, err)
                        })
                }
            },
            // 修改密码
            changePassword(e) {
                this.dialogFormVisible = true
                this.userDisabled = true
                this.form.uid = e.uid
                this.form.username = e.uname
                this.form.password = e.password
                this.form.account = e.account
                this.form.lib_id = parseInt(e.lib_id, 10)
            },
        },
        setup() {
            var userList = ref([])
            var libList = ref([])
            onMounted(async () => {
                try {
                    const responseLib = await axios.get('/api/libs_list') // 向后端发送GET请求获取数据
                    libList.value = responseLib.data.data.list
                    console.log('libList.value', libList.value)

                    const response = await axios.get('/api/user_list') // 向后端发送GET请求获取数据
                    userList.value = response.data.data.list
                } catch (error) {
                    console.error('请求失败:', error)
                }
            })
            return {
                userList,
                libList,
            }
        },
    }
</script>

<style>
    .root-page {
        /* display: flex; */
        /* justify-content: space-between; */
        width: 100%;
        background-color: #f2f2f2;
        height: 100%;
        /* padding-bottom: 20px;  */
        /* margin-top: 60px; */
    }
    .common-layout {
        width: 100%;
        margin-top: 80px; /*确保内容不会被顶部的banner遮挡*/
    }

    .demo-tabs > .el-tabs__content {
        padding: 32px;
        color: #6b778c;
        font-size: 32px;
        font-weight: 600;
    }

    .el-message {
        margin-top: 50px;
    }

    button.el-button.el-button--primary.el-button--small.is-link {
        margin: 0px;
    }
</style>
