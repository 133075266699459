// src/stores/useMainStore.js
import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
    state: () => ({
        var1: '默认值var1',
        var2: '',
        var3: '',
        JSFJ: [], //技术分解
        filledInventionPoint: [], //存储发明点
        SQR: [], // 存储申请人
        FMR: [], // 存储发明人
        ShenQingRi: [], //申请日
        GKDATE: [], //公开日
        JSXG: [], //技术效果
        ShouQuanRi: [], //授权日
        nowPage: 1, //当前是第几页
        pageSize: 10, //每页多少个
        sortType: '', //排序方式
        goSearchData: [], // FilterShow.vue点击筛选按钮，要同时更新SearchResults.vue的结果和NavMenu.vue的数字
        clickSearch: 1, // 点击搜索按钮的时候，左侧技术分解和分类统计进行刷新
        clickClear: 1, // 点击清空筛选项按钮的时候，左侧技术分解和分类统计进行刷新
        jsfj_father: null, // 存储技术分解节点id完整路径
        sqren_father: null, // 申请人节点id完整路径
        canUpdate: false, //是否可以刷新
    }),
    actions: {
        clickSearchPlus() {
            this.clickSearch = this.clickSearch + 1
        },
        clickClearPlus() {
            this.clickClear = this.clickClear + 1
        },
        setgoSearchData(value) {
            this.goSearchData = value
        },
        setpageSize(value) {
            this.pageSize = value
        },
        setnowPage(value) {
            this.nowPage = value
        },
        setsortType(value) {
            this.sortType = value
        },
        setVar1(value) {
            this.var1 = value
        },
        setVar2(value) {
            this.var2 = value
        },
        setVar3(value) {
            this.var3 = value
        },
        setFilledInventionPoint(value) {
            this.filledInventionPoint = value
        },
        // 新增一个发明点
        pushFilledInventionPoint(value) {
            this.filledInventionPoint.push(value)
        },
        // 删除一个发明点
        delItemFilledInventionPoint(value) {
            this.filledInventionPoint = this.filledInventionPoint.filter(
                (item) => item !== value,
            )
        },

        setJSXG(value) {
            this.JSXG = value
        },
        // 新增一个技术效果
        pushJSXG(value) {
            this.JSXG.push(value)
        },
        // 删除一个技术效果
        delItemJSXG(value) {
            this.JSXG = this.JSXG.filter((item) => item !== value)
        },

        setSQR(value) {
            this.SQR = value
        },

        pushItemSQR(value) {
            this.SQR.push(value)
            this.SQR = [...new Set(this.SQR)]
        },
        // 删除一个申请人
        delItemSQR(value) {
            this.SQR = this.SQR.filter((item) => item !== value)
            this.SQR = this.SQR.filter((item) => item.id !== value)
        },
        setFMR(value) {
            this.FMR = value
        },
        // 删除一个发明人
        delItemFMR(value) {
            this.FMR = this.FMR.filter((item) => item.id !== value)
        },

        setJSFJ(value) {
            this.JSFJ = value
        },

        setJsfjFather(value) {
            this.jsfj_father = value
        },

        setSqrenFather(value) {
            this.sqren_father = value
        },
        // 删除一个技术分解
        delItemJSFJ(value) {
            this.JSFJ = this.JSFJ.filter((item) => item !== value)
            this.JSFJ = this.JSFJ.filter((item) => item.id !== value)
        },
        pushItemJSFJ(value) {
            this.JSFJ.push(value)
            this.JSFJ = [...new Set(this.JSFJ)]
        },

        setZYGJDQ(value) {
            this.ZYGJDQ = value
        },
        // 删除一个主要国家/地区
        delItemZYGJDQ(value) {
            this.ZYGJDQ = this.ZYGJDQ.filter((item) => item.id !== value)
        },

        setFLZT(value) {
            this.FLZT = value
        },
        // 删除一个法律状态
        delItemFLZT(value) {
            this.FLZT = this.FLZT.filter((item) => item.id !== value)
        },

        setShenQingRi(value) {
            this.ShenQingRi = value
        },
        // 新增一个申请日
        pushShenQingRi(value) {
            this.ShenQingRi.push(`${value[0]}至${value[1]}`)
            // const huo = '(或) ';
            // this.ShenQingRi.push(`${huo}${value[0]}至${value[1]}`);
            // 删除第第一个的 或
            // this.ShenQingRi[0] = this.ShenQingRi[0].replace(huo,'');
        },
        // 删除一个申请日
        delItemShenQingRi(value) {
            this.ShenQingRi = this.ShenQingRi.filter((item) => item !== value)
        },

        // 新增一个公开日
        pushGKDATE(value) {
            this.GKDATE.push(`${value[0]}至${value[1]}`)
        },
        // 删除一个公开日
        delItemGKDATE(value) {
            this.GKDATE = this.GKDATE.filter((item) => item !== value)
        },

        // 新增一个授权日
        pushShouQuanRi(value) {
            this.ShouQuanRi.push(`${value[0]}至${value[1]}`)
        },
        // 删除一个授权日
        delItemShouQuanRi(value) {
            this.ShouQuanRi = this.ShouQuanRi.filter((item) => item !== value)
        },

        // 重置所有参数
        resetAllVar() {
            this.canUpdate = true
            this.filledInventionPoint = [] //存储发明点
            this.SQR = [] // 存储申请人
            this.FMR = [] // 存储发明人
            this.ZYGJDQ = [] // 存储主要国家/地区
            this.FLZT = [] // 法律状态
            this.ShenQingRi = [] // 申请日
            this.GKDATE = [] //公开日
            this.JSXG = [] //公开日
            this.ShouQuanRi = [] //授权日
            this.JSFJ = [] //技术分解
            this.jsfj_father = null
            setTimeout(() => {
                this.canUpdate = false
            }, 1000)
        },
    },
})
