// 这里封装一些公共的方法
// utils.js
import { reactive } from 'vue'
import { useMainStore } from './stores/useMainStore'
import axios from 'axios'
import { ElMessage } from 'element-plus'
// 搜索
// w
async function goSearch(s_stype = 'search_zhuangli') {
    try {
        const store = useMainStore()
        const data = {
            arg: {
                fmd: store.filledInventionPoint, //发明点
                sqren: store.SQR, // 申请人
                fmr: store.FMR, // 发明人
                gkgb: store.ZYGJDQ, // 主要国家/地区
                dqflzt: store.FLZT, // 法律状态
                sqr: store.ShenQingRi, // 申请日
                gkgr: store.GKDATE, //公开日
                jsxg: store.JSXG, //技术效果
                jsfj: store.JSFJ, //技术分解
                sqggr: store.ShouQuanRi, //授权日
                jsfj_father: store.jsfj_father, //新增参数，获取下级节点
                sqren_father: store.sqren_father,
            },
            otherArg: {
                nowPage: store.nowPage, // 当前页码
                pageSize: store.pageSize, // 当前页码
                sortType: store.sortType, //排序方式
            },
        }

        const post_url = '/api/' + s_stype

        const res = await axios.post(post_url, data)
        if (res.data.status == 500) {
            ElMessage({
                message: '查询失败，请重新登录',
                type: 'error',
                plain: true,
            })
        } else if (s_stype == 'search_zhuangli') {
            ElMessage({
                message: '查询成功',
                type: 'success',
                plain: true,
            })
        }

        const response = res
        // console.log('公共方法',response);
        return response
    } catch (error) {
        console.error('Error sending POST request:', error)
    }
}
// 创建一个 reactive 对象来存储公共方法
const sharedMethods = reactive({
    goSearch,
})

export default sharedMethods
