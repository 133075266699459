<template>
    <div class="main-container">
        <el-tabs
            type="border-card"
            stretch
            v-model="curTab"
            @tab-change="onChangeTabs">
            <!-- 技术分解 -->
            <el-tab-pane name="category" label="技术分解">
                <div class="menus" v-if="curTab === 'category'">
                    <el-scrollbar
                        :always="true"
                        style="
                            height: 750px;
                            width: 100%;
                            background-color: white;
                        "
                        @wheel="handleScroll"
                        ref="scrollbar">
                        <div style="height: 1200px; width: 100%">
                            <el-menu mode="vertical">
                                <el-tree
                                    v-loading="loadingJSFJ"
                                    element-loading-text="数据加载中.."
                                    style="max-width: 600px; min-height: 200px"
                                    node-key="id"
                                    :indent="6"
                                    :props="defaultProps"
                                    :load="loadNodeJSFJ"
                                    ref="treeRefJSFJ"
                                    lazy
                                    show-checkbox
                                    :default-checked-keys="defaultCheckedKeys"
                                    :default-expanded-keys="defaultExpandKeys"
                                    @check="handleCheckChangeJSFJ">
                                </el-tree>
                            </el-menu>
                        </div>
                    </el-scrollbar>
                </div>
            </el-tab-pane>
            <!-- 分类统计 -->
            <el-tab-pane name="tech" label="分类统计">
                <div class="menus" v-if="curTab === 'tech'">
                    <el-scrollbar
                        :always="true"
                        style="
                            height: 750px;
                            width: 100%;
                            background-color: white;
                        "
                        @wheel="handleScroll"
                        ref="scrollbar">
                        <div style="height: 1200px; width: 100%">
                            <el-menu mode="vertical">
                                <el-sub-menu
                                    :index="item.index"
                                    :border="false"
                                    v-for="(item, index) in subMenuListTech"
                                    :key="index">
                                    <template #title>
                                        <div
                                            style="
                                                display: flex;
                                                align-items: center;
                                            ">
                                            <img :src="item.icon" />
                                            <span style="margin-left: 5px">
                                                {{ item.label }}
                                            </span>
                                        </div>
                                    </template>
                                    <div v-if="index < 4">
                                        <el-input
                                            v-show="item.showSearch"
                                            style="width: 90%"
                                            v-model="item.searchVal"
                                            :placeholder="`搜索${item.label}`"
                                            @input="item.searchMethod" />
                                        <el-tree-v2
                                            v-if="item.isVirtual"
                                            :ref="item.ref"
                                            :height="150"
                                            show-checkbox
                                            v-loading="loading[item.type]"
                                            :data="item.data"
                                            :default-checked-keys="
                                                item.defaultCheckedKeys
                                            "
                                            :props="defaultProps"
                                            :filter-method="item.filterMethod"
                                            @check-change="
                                                item.checkedChange
                                            " />
                                        <el-tree
                                            v-else
                                            v-loading="loadingSQR"
                                            node-key="id"
                                            lazy
                                            show-checkbox
                                            :default-checked-keys="
                                                defaultCheckedSQRKeys
                                            "
                                            :default-expanded-keys="
                                                defaultExpandSQRKeys
                                            "
                                            :ref="item.ref"
                                            :indent="6"
                                            :props="defaultProps"
                                            :load="loadNodeMethod[item.type]"
                                            @check="item.checkedChange">
                                        </el-tree>
                                    </div>

                                    <el-menu-item
                                        index="5-1"
                                        v-if="item.label === '申请日'">
                                        <el-date-picker
                                            v-model="ShenQingRiDate"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始"
                                            end-placeholder="结束"
                                            size="small" />
                                        <el-button
                                            color="#00245a"
                                            class="btn-tongyi"
                                            plain
                                            @click="
                                                handleDateRangeAddShenQingRi
                                            "
                                            >确定</el-button
                                        >
                                    </el-menu-item>

                                    <el-menu-item
                                        index="6-1"
                                        v-if="item.label === '公开日'">
                                        <el-date-picker
                                            v-model="GKDATE"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始"
                                            end-placeholder="结束"
                                            size="small" />
                                        <el-button
                                            color="#00245a"
                                            class="btn-tongyi"
                                            plain
                                            @click="handleDateRangeAddGKDATE"
                                            >确定</el-button
                                        >
                                    </el-menu-item>

                                    <el-menu-item
                                        index="7-1"
                                        v-if="item.label === '发明点'">
                                        <el-input
                                            v-model="inputInventionPoint"
                                            placeholder="掺杂XXX" />
                                        <el-button
                                            color="#00245a"
                                            class="btn-tongyi"
                                            plain
                                            @click="handleInventionPointAdd"
                                            >确定</el-button
                                        >
                                    </el-menu-item>

                                    <el-menu-item
                                        index="8-1"
                                        v-if="item.label === '技术效果'">
                                        <el-input
                                            v-model="inputJSXG"
                                            placeholder="抑制XX溶出" />
                                        <el-button
                                            color="#00245a"
                                            class="btn-tongyi"
                                            plain
                                            @click="handleJSXGAdd"
                                            >确定</el-button
                                        >
                                    </el-menu-item>

                                    <el-menu-item
                                        index="9-1"
                                        v-if="item.label === '授权日'">
                                        <el-date-picker
                                            v-model="ShouQuanRi"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始"
                                            end-placeholder="结束"
                                            size="small" />
                                        <el-button
                                            color="#00245a"
                                            class="btn-tongyi"
                                            plain
                                            @click="
                                                handleDateRangeAddShouQuanRi
                                            "
                                            >确定</el-button
                                        >
                                    </el-menu-item>
                                </el-sub-menu>
                            </el-menu>
                        </div>
                    </el-scrollbar>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script setup>
    import { nextTick, ref, watch } from 'vue'
    import { useMainStore } from '../stores/useMainStore'
    import sqrIcon from '@/assets/iconSvg/sqr.svg'
    import fmrIcon from '@/assets/iconSvg/fmr.svg'
    import guojiaIcon from '@/assets/iconSvg/guojia.svg'
    import flztIcon from '@/assets/iconSvg/flzt.svg'
    import shenqingriIcon from '@/assets/iconSvg/shenqingri.svg'
    import gkrIcon from '@/assets/iconSvg/gkr.svg'
    import fmdIcon from '@/assets/iconSvg/fmd.svg'
    import jsxgIcon from '@/assets/iconSvg/jsxg.svg'
    import shouquanriIcon from '@/assets/iconSvg/shouquanri.svg'
    import sharedMethods from '@/utils.js'

    const curTab = ref('category')
    const store = useMainStore()

    const loadingJSFJ = ref(false)
    const loadingSQR = ref(false)

    const GKDATE = ref('')
    const ShouQuanRi = ref('')
    const ShenQingRiDate = ref('')

    const defaultProps = {
        children: 'children',
        label: 'label',
        isLeaf: 'isLeaf',
    }

    const handleScroll = (event) => {
        const el = event.currentTarget.querySelector('.el-scrollbar__wrap') // 获取实际滚动的元素
        const scrollTop = el.scrollTop
        const scrollHeight = el.scrollHeight
        const clientHeight = el.clientHeight

        const atBottom = scrollTop + clientHeight >= scrollHeight
        const atTop = scrollTop <= 0

        if ((event.deltaY > 0 && atBottom) || (event.deltaY < 0 && atTop)) {
            event.preventDefault()
        }
    }

    /** 技术分解 */
    // const queryJSFJ = ref('') //控制下拉框的搜索框
    const treeRefJSFJ = ref(null)

    // 载入技术分解

    const jsfjResolve = ref(null)
    const jsfjNode = ref(null)

    const defaultCheckedKeys = ref([])
    const defaultExpandKeys = ref([])
    const loadNodeJSFJ = async (node, resolve) => {
        jsfjResolve.value = resolve
        jsfjNode.value = node
        node.childNodes = []
        node.children = []

        // 存储点击的节点id，给下次调用参数赋值

        // if (node.level === 0) {
        store.setJsfjFather(node?.data?.id || null)

        selectedNodeIds.value.push(node.id)

        // 接口函数中会自动读取刚刚存储的节点参数
        try {
            loadingJSFJ.value = true
            const { data } = await sharedMethods.goSearch('jsfj_list')
            loadingJSFJ.value = false
            if (data.data.length) {
                const resDatas = data.data.map((x) => {
                    x.isLeaf = true
                    if (x.children && JSON.stringify(x.children) === '[]') {
                        x.isLeaf = false
                    }

                    return x
                })

                resolve(resDatas)
                nextTick(() => {
                    const storeJSFJ = store.JSFJ
                    if (storeJSFJ) {
                        const inputArray = storeJSFJ.map((x) => x.id)
                        defaultExpandKeys.value = setSelectedDatas(inputArray)
                        defaultCheckedKeys.value = inputArray
                    }
                })
            } else {
                resolve([])
            }
        } catch (error) {
            resolve([])
        }
        // }
    }

    function setSelectedDatas(inputArray) {
        const result = inputArray.reduce((accumulator, currentValue) => {
            const parts = currentValue.split('=>')
            for (let i = 0; i < parts.length - 1; i++) {
                const path = parts.slice(0, i + 1).join('=>')
                if (!accumulator.includes(path)) {
                    accumulator.push(path)
                }
            }
            return accumulator
        }, [])

        return result
    }

    const selectedNodeIds = ref([])

    // 勾选技术分解
    const handleCheckChangeJSFJ = (data, { checkedKeys }) => {
        const isChecked = checkedKeys.some((x) => x === data.id)
        if (isChecked) {
            store.pushItemJSFJ(data)
        } else {
            store.delItemJSFJ(data)
        }
        goSearchFilter()
    }

    /** 分类统计 */

    // 勾选申请人
    const sqrRef = ref(null)
    const handleCheckChangeSQR = (data, { checkedKeys }) => {
        const isChecked = checkedKeys.some((x) => x === data.id)
        if (isChecked) {
            store.pushItemSQR(data)
        } else {
            store.delItemSQR(data)
        }
        goSearchFilter()
    }

    // 勾选发明人
    const treeRefFMR = ref()
    const handleCheckChangeFMR = () => {
        const checkedNodes = treeRefFMR.value[0].getCheckedNodes()
        store.setFMR(checkedNodes)
        goSearchFilter()
    }

    // 发明人查询
    const onQueryChangedFMR = (query) => {
        treeRefFMR.value[0].filter(query) // Assuming `treeRefSQR.value` is correctly set in your component lifecycle.
    }

    // 发明人查询结果
    const filterMethodFMR = (query, node) => {
        return node.label.includes(query)
    }

    // 勾选国家地区
    const treeRefZYGJDQ = ref()
    const handleCheckChangeZYGJDQ = () => {
        const checkedNodes = treeRefZYGJDQ.value[0].getCheckedNodes()
        store.setZYGJDQ(checkedNodes)
        goSearchFilter()
    }

    // 国家地区查询
    const onQueryChangedZYGJDQ = (query) => {
        treeRefZYGJDQ.value[0].filter(query)
    }

    // 国家地区查询结果
    const filterMethodZYGJDQ = (query, node) => {
        return node.label.includes(query)
    }

    // 勾选法律
    const treeRefFLZT = ref()
    const handleCheckChangeFLZT = () => {
        const checkedNodes = treeRefFLZT.value[0].getCheckedNodes()
        store.setFLZT(checkedNodes)
        goSearchFilter()
    }

    // 法律查询
    const onQueryChangedFLZT = (query) => {
        treeRefFLZT.value[0].filter(query) // Assuming `treeRefSQR.value` is correctly set in your component lifecycle.
    }

    // 法律查询结果
    const filterMethodFLZT = (query, node) => {
        return node.label.includes(query)
    }

    // 分类统计menu
    const subMenuListTech = ref([
        {
            index: '1',
            icon: sqrIcon,
            label: '申请(专利权)人',
            type: 'sqr_list',
            ref: 'sqrRef',
            data: [],
            checkedChange: (data, node) => handleCheckChangeSQR(data, node),
        },
        {
            index: '2',
            icon: fmrIcon,
            label: '发明人',
            type: 'fmr_list',
            isVirtual: true,
            showSearch: false,
            searchVal: '',
            data: [],
            defaultCheckedKeys: [],
            ref: 'treeRefFMR',
            checkedChange: (query) => handleCheckChangeFMR(query),
            filterMethod: (query, node) => filterMethodFMR(query, node),
            searchMethod: (query) => onQueryChangedFMR(query),
        },
        {
            index: '3',
            icon: guojiaIcon,
            label: '主要国家/地区',
            type: 'gjdq_list',
            isVirtual: true,
            showSearch: false,
            searchVal: '',
            data: [],
            defaultCheckedKeys: [],
            ref: 'treeRefZYGJDQ',
            checkedChange: (query) => handleCheckChangeZYGJDQ(query),
            filterMethod: (query, node) => filterMethodZYGJDQ(query, node),
            searchMethod: (query) => onQueryChangedZYGJDQ(query),
        },
        {
            index: '4',
            icon: flztIcon,
            label: '法律状态',
            type: 'flzt_list',
            isVirtual: true,
            showSearch: false,
            searchVal: '',
            data: [],
            ref: 'treeRefFLZT',
            checkedChange: (query) => handleCheckChangeFLZT(query),
            filterMethod: (query, node) => filterMethodFLZT(query, node),
            searchMethod: (query) => onQueryChangedFLZT(query),
        },
        {
            index: '5',
            icon: shenqingriIcon,
            label: '申请日',
            defaultCheckedKeys: [],
        },
        {
            index: '6',
            icon: gkrIcon,
            label: '公开日',
            defaultCheckedKeys: [],
        },
        {
            index: '7',
            icon: fmdIcon,
            label: '发明点',
            defaultCheckedKeys: [],
        },
        {
            index: '8',
            icon: jsxgIcon,
            label: '技术效果',
            defaultCheckedKeys: [],
        },
        {
            index: '9',
            icon: shouquanriIcon,
            label: '授权日',
            defaultCheckedKeys: [],
        },
    ])

    const sqrResolve = ref(null)
    const sqrNode = ref(null)
    const defaultExpandSQRKeys = ref([])
    const defaultCheckedSQRKeys = ref([])
    const loading = ref({
        fmr_list: false,
        gjdq_list: false,
        flzt_list: false,
    })
    const loadNodeMethod = {
        // 载入申请人
        async sqr_list(node, resolve) {
            sqrResolve.value = resolve

            sqrNode.value = node
            // 先清空当前节点的子节点数据
            node.children = []
            node.childNodes = []
            // 存储点击的节点id，给下次调用参数赋值

            store.setSqrenFather(node?.data?.id || null)
            loadingSQR.value = true
            const { data } = await sharedMethods.goSearch('sqr_list')
            loadingSQR.value = false

            if (data.data?.length) {
                const resDatas = data.data.map((x) => {
                    x.isLeaf = true
                    if (x.children && JSON.stringify(x.children) === '[]') {
                        x.isLeaf = false
                    }

                    return x
                })
                console.log('resDatas', resDatas)

                resolve(resDatas)
                nextTick(() => {
                    // const extData = setSelectedDatas(resDatas.map((x) => x?.id))
                    // defaultExpandSQRKeys.value = extData
                    if (store.SQR) {
                        nextTick(() => {
                            const storeSQR = store.SQR
                            if (storeSQR) {
                                const inputArray = storeSQR.map((x) => x?.id)
                                defaultExpandSQRKeys.value =
                                    setSelectedDatas(inputArray)
                                defaultCheckedSQRKeys.value = inputArray
                            }
                        })
                    }
                })
            }
        },

        // 载入发明人，国家，法律状态

        async loadDatas(apiType) {
            loading.value[apiType] = true
            const { data } = await sharedMethods.goSearch(apiType)
            loading.value[apiType] = false
            if (data.data?.length) {
                const resDatas = data.data.map((x) => {
                    x.isLeaf = true
                    if (x.children && JSON.stringify(x.children) === '[]') {
                        x.isLeaf = false
                    }
                    return x
                })

                let storeData = []
                if (apiType === 'fmr_list') {
                    storeData = store.FMR || []
                }

                if (apiType === 'gjdq_list') {
                    storeData = store.ZYGJDQ || []
                }

                if (apiType === 'flzt_list') {
                    storeData = store.FLZT || []
                }

                subMenuListTech.value.forEach((x) => {
                    if (x.type === apiType) {
                        x.data = resDatas

                        x.defaultCheckedKeys = storeData.map((x) => x?.id)
                    }
                })
            }
        },
    }

    async function goSearchFilter() {
        try {
            store.setnowPage(1)
            const response = await sharedMethods.goSearch('search_zhuangli')
            store.setgoSearchData(response)

            // store.clickSearchPlus();// 通知左侧技术分解和分类统计刷新
        } catch (error) {
            console.error('Error sending POST request:', error)
        }
    }

    // 处理申请日的日期范围选择
    const handleDateRangeAddShenQingRi = () => {
        if (ShenQingRiDate.value) {
            // 时区的原因，如果直接获取会晚一天，需要转换一下
            const formattedDates = ShenQingRiDate.value.map((date) => {
                const localDate = new Date(date)
                return localDate
                    .toLocaleDateString('zh-CN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    .replace(/\//g, '-')
            })

            // 数据存到pinia
            store.pushShenQingRi(formattedDates)
            goSearchFilter()
        }
    }
    // 处理公开日的日期范围选择
    const handleDateRangeAddGKDATE = () => {
        if (GKDATE.value) {
            // 时区的原因，如果直接获取会晚一天，需要转换一下
            const formattedDates = GKDATE.value.map((date) => {
                const localDate = new Date(date)
                return localDate
                    .toLocaleDateString('zh-CN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    .replace(/\//g, '-')
            })

            // 数据存到pinia
            store.pushGKDATE(formattedDates)
            goSearchFilter()
        }
    }

    // 处理授权日的日期范围选择
    const handleDateRangeAddShouQuanRi = () => {
        if (ShouQuanRi.value) {
            // 时区的原因，如果直接获取会晚一天，需要转换一下
            const formattedDates = ShouQuanRi.value.map((date) => {
                const localDate = new Date(date)
                return localDate
                    .toLocaleDateString('zh-CN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    .replace(/\//g, '-')
            })

            // 数据存到pinia
            store.pushShouQuanRi(formattedDates)
            goSearchFilter()
        }
    }

    // 发明点，点击添加
    const inputInventionPoint = ref('')
    const handleInventionPointAdd = () => {
        store.pushFilledInventionPoint(inputInventionPoint.value)
        goSearchFilter()
    }

    // 技术效果，点击添加
    const inputJSXG = ref('')
    const handleJSXGAdd = () => {
        store.pushJSXG(inputJSXG.value)
        goSearchFilter()
    }

    /** 公共方法 */

    function reLoad() {
        loadNodeMethod.loadDatas('fmr_list')
        loadNodeMethod.loadDatas('gjdq_list')
        loadNodeMethod.loadDatas('flzt_list')
    }

    // tab切换
    function onChangeTabs() {
        if (curTab.value === 'tech') {
            reLoad()
        } else {
            loadNodeJSFJ(jsfjNode.value, jsfjResolve.value)
        }
    }

    //仅仅清空筛选时调用
    watch(
        () => store.canUpdate,
        (newVal) => {
            if (newVal) {
                if (curTab.value === 'tech') {
                    reLoad()
                    loadNodeMethod.sqr_list(sqrNode.value, sqrResolve.value) // 更新申请人
                    // setChecked()
                } else {
                    loadNodeJSFJ(jsfjNode.value, jsfjResolve.value) // 更新技术分解
                    // setChecked()
                }
            }
        },
    )
    // 监听变量，当发生变化时，及时更新
    watch(
        () => store.JSFJ,
        (newVal) => {
            const checked = newVal.reduce((acc, current) => {
                return [...acc, current.id]
            }, [])
            if (treeRefJSFJ.value) {
                treeRefJSFJ.value.setCheckedKeys(checked)
            }
        },
    )

    // 监听变量，当发生变化时，及时更新
    watch(
        () => store.SQR,
        (newVal) => {
            const checked = newVal.reduce((acc, current) => {
                return [...acc, current.id]
            }, [])

            if (sqrRef.value) {
                sqrRef.value[0].setCheckedKeys(checked)
            }

            if (!store.canUpdate) {
                reLoad()
            }
        },
    )

    // 监听变量，当发生变化时，及时更新
    watch(
        () => store.FMR,
        (newVal) => {
            const checked = newVal.reduce((acc, current) => {
                return [...acc, current.label]
            }, [])

            if (treeRefFMR.value) {
                treeRefFMR.value[0].setCheckedKeys(checked)
            }

            if (!store.canUpdate) {
                loadNodeMethod.loadDatas('gjdq_list')
                loadNodeMethod.loadDatas('flzt_list')
                loadNodeMethod.sqr_list(sqrNode.value, sqrResolve.value) // 更新申请人
                loadNodeJSFJ(jsfjNode.value, jsfjResolve.value) // 更新技术分解
            }
        },
    )

    // 监听变量，当发生变化时，及时更新
    watch(
        () => store.ZYGJDQ,
        (newVal) => {
            const checked = newVal.reduce((acc, current) => {
                return [...acc, current.label]
            }, [])
            if (treeRefZYGJDQ.value) {
                treeRefZYGJDQ.value[0].setCheckedKeys(checked)
            }
            if (!store.canUpdate) {
                loadNodeMethod.loadDatas('fmr_list') // 更新发明人
                loadNodeMethod.loadDatas('flzt_list') // 更新法律状态
                loadNodeMethod.sqr_list(sqrNode.value, sqrResolve.value) // 更新申请人
                loadNodeJSFJ(jsfjNode.value, jsfjResolve.value) // 更新技术分解
            }
        },
    )

    // 监听变量，当发生变化时，及时更新
    watch(
        () => store.FLZT,
        (newVal) => {
            const checked = newVal.reduce((acc, current) => {
                return [...acc, current.label]
            }, [])
            if (treeRefFLZT.value) {
                treeRefFLZT.value[0].setCheckedKeys(checked)
            }
            if (!store.canUpdate) {
                loadNodeMethod.loadDatas('fmr_list') // 更新发明人
                loadNodeMethod.loadDatas('gjdq_list') // 更新法律状态
                loadNodeMethod.sqr_list(sqrNode.value, sqrResolve.value) // 更新申请人
                loadNodeJSFJ(jsfjNode.value, jsfjResolve.value) // 更新技术分解
            }
        },
    )
</script>
<style lang="scss" scoped>
    .menus {
        width: 100%;
    }
    .main-container {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

    /*隐藏原生滚动条*/
    :deep(.el-scrollbar__wrap) {
        overflow-x: hidden; /*隐藏横向滚动条*/
    }

    /*自定义滚动条样式*/
    :deep(.el-scrollbar__bar.is-vertical) {
        width: 1px; /*滚动条宽度*/
        background-color: #f1f1f1; /*滚动条背景色*/
        border-radius: 6px; /*滚动条圆角*/
    }

    :deep(.el-scrollbar__thumb.is-vertical) {
        background-color: #888; /*滚动条滑块颜色*/
        border-radius: 6px; /*滚动条滑块圆角*/
    }

    :deep(.el-scrollbar__thumb.is-vertical:hover) {
        background-color: #555; /*滚动条滑块悬停颜色*/
    }

    :deep(.el-scrollbar__bar) {
        opacity: 1; /*滚动条始终显示*/
    }

    :deep(.el-tabs--border-card > .el-tabs__content) {
        padding: 0 !important;
    }
</style>
